.time-track-notes-modal {
  .swal2-html-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  & input,
  select {
    width: 100% !important;
    margin-left: 0;
    margin-right: 0;
  }
}
